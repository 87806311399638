@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwindcssを導入すると、hタグのフォントサイズが変わるのを防ぐ */
h1 {
  font-size: 48px;
  line-height: 1;
}
h2 {
  font-size: 36px;
  line-height: 1.333;
}
h3 {
  font-size: 24px;
  line-height: 1;
}
h4,
h5,
h6 {
  font-size: 16px;
  line-height: 1.5;
}

body {
  min-height: 100vh;
}
